import * as React from "react";
import '../css/components/Footer.css';
import securePurchase from '../images/securePurchase.webp'
import modalIco from '../images/logo-lanegra.png';
import webPay from '../images/webPay.webp'
import BrevoNewsletter from "./BrevoNewsletter";

function Footer() {
  return (
    <footer className="Footer">
        <div className="Footer__newsletter">
            <div className="container">
                <div className="Footer__newsletter--group">
                    <div className="Footer__newsletter--container">
                        <div className="Footer__newsletter--title">
                            <h2>Quiero recibir ofertas y novedades</h2>
                        </div>
                        <BrevoNewsletter />
                    </div>
                </div>
            </div>
        </div>
        <div className="Footer__payments">
            <div className="container">
                <div className="Footer__payments--container">
                    <div className="Footer__payments--payment">
                        <img src={securePurchase} alt="" />
                    </div>
                    <div className="Footer__payments--payment">
                        <img src={webPay} alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div className="Footer__bottom">
            <div className="container">
                <div className="Footer__bottom--heading">
                    <div className="Footer__bottom--as">
                        <div className="Footer__bottom--as-a">
                            <ul>
                                <h4>Donde La Negra</h4>
                                <li><a href="/">Inicio</a></li>
                                <li><a href="/about">Quienes Somos</a></li>
                                <li><a href="/club-de-socios">Club La Negra</a></li>
                                {/* <li><a href="/recetas">Recetas</a></li> */}
                                {/* <li><a href="/categories">Tienda</a></li> */}
                                <li><a href="/my-account/">Mi Cuenta</a></li>
                                <li><a href="https://api.whatsapp.com/send?phone=56949689663&text=Hola,%20necesito%20información%20de%3A" target="__blank">Contacto</a></li>
                            </ul>
                        </div>
                        <div className="Footer__bottom--as-a">
                            <ul>
                                <h4>Servicio Al Cliente</h4>
                                <li><a href="/shipping-conditions">Envío y Devoluciones</a></li>
                                <li><a href="/terms-and-conditions">Términos y Condiciones</a></li>
                                <li><a href="/faqs">Preguntas Frecuentes</a></li>
                                <li><a href="https://api.whatsapp.com/send?phone=56949689663&text=Hola!%20Quiero%20saber%20sobre%20mi%20pedido%20N%C2%B0%3A" target="_blank" rel="noreferrer">Seguimiento de Pedido</a></li>
                                <li><a href="/informacion-despacho">Información Despachos</a></li>
                            </ul>
                        </div>
                        <div className="Footer__bottom--as-a">
                            <ul>
                                <h4>Contacto</h4>
                                <li><a href="tel:+56949689663" target="_blank" rel="noreferrer">+56 9 4968 9663</a></li>
                                <li><a href="mailto:ventas@dondelanegra.cl" target="_blank" rel="noreferrer">ventas@dondelanegra.cl</a></li>
                            </ul>
                        </div>
                        <div className="Footer__bottom--as-a socials">
                            <h4>Síguenos</h4>
                            <ul>
                                <li><a href="https://www.instagram.com/dondelanegra.cl/?hl=es-la" target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.facebook.com/dondelanegradistribuidora" target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook-f"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="Footer__bottom--banner">
                        <img src={modalIco} alt="" />
                    </div>
                </div>
                <div className="Footer__bottom--rights">
                    <div className="Footer__bottom--rights-text">
                        <p>© 2018 - 2025</p>
                    </div>
                    <div className="Footer__bottom--rights-developBy">
                        <p>Desarrollado por Franco y Andrés</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer;