import React, { useState, useEffect } from "react";
import logo from '../images/logo_desk_x2-3.webp';
import logo_movil from '../images/logo_dln_movil.png';
import search from '../images/search.png';
import bannerHeaderSubMenu from '../images/NW_MenuWeb.webp';
// import shoppingCart from '../images/shopping-cart.png';
import shoppingCartPlus from '../images/shopping-cart-white.png';
import userImage from '../images/user-white.png';
import address from '../images/address.png';
import "../css/components/Header.css";
import productImage from '../images/productHoverImage.jpg';
import barsMobile from '../images/bars.png';
// import menuMobile from '../images/menuMobile.png';
import discountIco from '../images/discount.png';
import menuIcon from '../images/bars-header.png';
import closeIconWhite from "../images/closeWhite.png"
import { FloatCart } from "./FloatCart";
import  {AdultModal}  from "./AdultModal";
import {MyContext} from '../context/AppContext';
import { SearchInput } from "./Search/SearchInput";
import "../css/components/Styles.css"


function Header({ updateSearchQuery  }) {
    // console.log(process.env.BASE_URL)
    const getLocalStorageItem = (key) => {
        if (typeof localStorage !== 'undefined') {
            return localStorage.getItem(key);
        }
        return null;
    };
    const dataProductsCart = getLocalStorageItem("products_cart");
    const user = JSON.parse(getLocalStorageItem("user")) || false;
    const [isCartActive, setIsCartActive] = useState(false);
    const [isCartFloatCouponActive, setIsCartFloatCouponActive] = useState(false);
    const [showHeaderMobile, setShowHeaderMobile] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [activeCategory, setActiveCategory] = useState('Categorías');
    const [quantity, setQuantity] = useState(1);
    const {state, cartModal, modalCart } = React.useContext(MyContext);
    const mainAddress = user?.addresses?.find(address => address.main === 1);
      // Nuevo estado para controlar la visibilidad
      const [isCheckoutPage, setIsCheckoutPage] = useState(false);
      const [isMobile, setIsMobile] = useState(false);
    
      useEffect(() => {
        if (typeof window !== 'undefined') {
          // Detectar ruta
          const checkPath = () => {
            setIsCheckoutPage(window.location.pathname == '/checkout/');
          };
          
          // Detectar viewport
          const checkMobile = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
          };
          
          // Configurar listeners
          checkPath();
          checkMobile();
          
          window.addEventListener('popstate', checkPath);
          window.addEventListener('resize', checkMobile);
    
          return () => {
            window.removeEventListener('popstate', checkPath);
            window.removeEventListener('resize', checkMobile);
          };
        }
      }, []);
    
      // Determinar clases condicionales
      const shouldHide = isCheckoutPage && !user;

      const searchClasses = shouldHide ? (isMobile ? 'hide-on-checkout mobile' : 'hide-on-checkout desktop') : '';
      const mobileBarsClasses = shouldHide && isMobile ? 'hide-on-checkout mobile' : '';

    // console.log(shouldHide);
    const [searchQuery, setSearchQuery] = React.useState('');
    // Inicializar totalStock a 0 por defecto
    let totalStock = 0;
      // Verificar si dataProductsCart no es null
      if (dataProductsCart) {
        const productsArray = JSON.parse(dataProductsCart);
        // Usar reduce solo si productsArray es un array
        if (Array.isArray(productsArray)) {
            totalStock = productsArray.reduce((total, product) => total + product.quantity, 0);
        }
    }

    const handleQuantityChange = (newQuantity) => {
        if (!isNaN(newQuantity)) {
        setQuantity(Math.max(1, newQuantity)); // Asegura que el valor mínimo sea 1
        }
    };

    const handleIncrement = () => {
        handleQuantityChange(quantity + 1);
    };

    const handleDecrement = () => {
        if (quantity > 1) {
        handleQuantityChange(quantity - 1);
        }
    };

    const handleCartDisplay = () => {
        setIsCartActive(!isCartActive);
        if (isCartActive == true) {
            document.body.style.overflow = "initial";
        } else {
            document.body.style.overflow = "hidden";
        }
    };

    const handleFloatCouponDisplay = () => {
        setIsCartFloatCouponActive(!isCartFloatCouponActive);
    };
    const handleSearchChange = (e) => {
        updateSearchQuery(e);
        // setSearchQuery(e.target.value);
    };
    const handleAccountModal = () => {
        setShowAccountModal(!showAccountModal);
    };

    const [subMenuClass, setSubMenuClass] = useState('');

    const handleMouseEnter = () => {
        setSubMenuClass('subMenuActive');
    };

    const handleMouseLeave = () => {
        setSubMenuClass('');
    };
    const truncateString = (str, num) => {
        return str?.length > num ? str.slice(0, num) + '...' : str;
      };
    const openModalCart = () => {
        modalCart(true);

        // const transformedItems = JSON.parse(getLocalStorageItem("products_cart"))?.map(item => ({
        //     item_name: item.name,
        //     item_id: item.sku,
        //     price: item.price.toString(),
        //     item_brand: item.brand,
        //     item_category: item.category, 
        //     quantity: item.quantity,
        //     tags: item.tags,
        // }));
        
        // const gtmData = {
        //     event: "view_cart",
        //     pagePath: "float-cart",
        //     pageTitle: "Modal del carrito",
        //     additionalData: {
        //         ecommerce: {
        //             items: transformedItems
        //         }
        //     }
        // };
        // GTMDataLayerPusher(gtmData);
        // console.log(cartModal.payload);
    }

  return (
    <>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossorigin="anonymous" referrerpolicy="no-referrer" />
        <header className="Header">
            <div className="Header__tobar">
                <div className="container">
                    <div className="Header__tobar--container">
                        <div className="Header__tobar--text">
                            <p>¡Pide antes de las 18:00 PM y recibe al siguiente día! <a href="/informacion-despacho/">(Ver comunas)</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="Header__sticky">
            <div className="Header__middlebar">
                <div className="container">
                    <div className="Header__middlebar--container">
                        <div className="Header__middlebar--mobileBars">
                            <img src={menuIcon} alt="" onClick={()=>{ setShowHeaderMobile(true); document.body.style.overflow = 'hidden';}} />
                        </div>
                        <div className="Header__middlebar--logo">
                            <a href="/">
                                <img className="desktop_logo" src={logo_movil} alt="" />
                                <img className="mobile_logo" src={logo_movil} alt="" />
                            </a>
                        </div>
                        <div className="Header__middlebar--links">
                            <ul>
                                <li
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                <a className="subMenuItem">Categorías <button><i className="fa-solid fa-angle-down"></i></button></a>
                                <div className={`HeaderSubMenu ${subMenuClass}`}>
                                    <div className="container">
                                        <div className="HeaderSubMenu__container">
                                            <div className="HeaderSubMenu__right">
                                                <div className="HeaderSubMenu__right--title">
                                                    <h4>Negra Week⚡</h4>
                                                </div>
                                                <div className="HeaderSubMenu__right--banner">
                                                    <a href="/etiqueta-producto/negradays/">
                                                        <img src={bannerHeaderSubMenu} alt="" />
                                                    </a>
                                                </div>
                                                <div className="HeaderSubMenu__right--arrow">
                                                    <h4>Comprar Ahora!</h4>
                                                    <div className="HeaderSubMenu__right--arrow-icon">
                                                        <a href="/etiqueta-producto/negradays/">
                                                            <i className="fa-solid fa-angle-right"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="HeaderSubMenu__left">
                                                <div className="HeaderSubMenu__left--links">
                                                    <div className="HeaderSubMenu__left--links-link">
                                                        <h4>Licores</h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/categoria-producto/whiskey/">Whiskey</a>
                                                                <a href="/categoria-producto/vinos-y-espumantes/">Vinos</a>
                                                                <a href="/categoria-producto/espumantes/">Espumantes</a>
                                                                <a href="/categoria-producto/cervezas/">Cervezas</a>
                                                                <a href="/categoria-producto/gin/">Gin</a>
                                                                <a href="/categoria-producto/pisco/">Pisco</a>
                                                                <a href="/categoria-producto/ron/">Ron</a>
                                                                <a href="/categoria-producto/tequilas/">Tequila</a>
                                                                <a href="/categoria-producto/vodka/">Vodka</a>
                                                                <a href="/categoria-producto/otros-licores/">Otros Licores</a>
                                                                <a href="/categoria-producto/bebidas/">Bebidas y energéticas</a>
                                                                <a href="/categoria-producto/packs/">Packs</a>
                                                                <a href="/categoria-producto/ocb-y-clipper/">Ocb y clipper</a>
                                                                {/* <a href="#">Ver Todos</a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="HeaderSubMenu__left--links-link">
                                                        <h4>Vinos Tintos</h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/categoria-producto/cabernet-sauvignon/">Cabernet Sauvignon</a>
                                                                <a href="/categoria-producto/carmenere/">Carmenere</a>
                                                                <a href="/categoria-producto/merlot/">Merlot</a>
                                                                <a href="/categoria-producto/ensamblaje/">Ensamblaje</a>
                                                                <a href="/categoria-producto/syrah/">Syrah</a>
                                                                <a href="/categoria-producto/otras-cepas/">Otras Cepas</a>
                                                                {/* <a href="/categoria-producto/vinos-y-espumantes/?filter_tipo-de-vino=tinto">Ver Todos</a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="HeaderSubMenu__left--links-link">
                                                        <h4>Vinos Blancos</h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/categoria-producto/chardonnay/">Chardonnay</a>
                                                                <a href="/categoria-producto/sauvignon-blanc/">Sauvignon Blanc</a>
                                                                {/* <a href="/categoria-producto/vinos-y-espumantes/?filter_tipo-de-vino=blanco">Ver Todos</a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="HeaderSubMenu__left--links-link">
                                                        <h4>Otras Opciones</h4>
                                                        <ul>
                                                            <li>
                                                                <a href="/categoria-producto/grandes-vinos/">Grandes Vinos</a>
                                                                <a href="/categoria-producto/vinos-formatos-familiares/">Formatos Familiares</a>
                                                                {/* <a href="#">Ver Todos</a> */}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                                <li><a href="/etiqueta-producto/negradays/">Negra Week⚡</a></li>
                                <li><a href="/categoria-producto/packs/">Packs</a></li>
                            </ul>
                        </div>
                        <SearchInput 
                            query={searchQuery} 
                            onSearch={handleSearchChange}
                            clase={searchClasses}
                        />
                        {/* <div className="Header__middlebar--links">
                            <ul>
                                <li><a href="/club-de-socios">Club La Negra</a></li>
                                <li><a href="/recetas">Recetas</a></li>
                            </ul>
                        </div> */}
                        <div className="Header__middlebar--right">
                            <div className="Header__middlebar--actions">
                                {/* {user && (
                                    <a href={"/my-account"} className="Header__middlebar--actions-action" id="notLogged">
                                        <img src={userImage} alt="" />
                                        <span>Hola {user.name?.length > 10 ? user.name.slice(0, 10) + "..." : user.name}!</span>
                                        
                                    </a>
                                )} */}
                                {user && (
                                    <a href="/my-account" className="Header__middlebar--actions-action" id="logged">
                                        <img src={userImage} alt="" />
                                        <div className="accountDetails">
                                            <span>Hola {user.name}!</span>
                                            <p>{new Intl.NumberFormat('de-DE').format(user.totalPoints)} NegraPoints</p>
                                        </div>
                                    </a>
                                )}
                                {!user && (
                                    <div className="Header__middlebar--actions-action" id="notLogged" onClick={handleAccountModal}>
                                        <img src={userImage} alt="" />
                                        <span>Inicia Sesión</span>
                                    </div>
                                )}
                                
                                <a href="/my-directions" className="Header__middlebar--actions-action" id="HeaderAddress">
                                    <img style={{width: '27px'}} src={address} alt="" />
                                    <div>
                                        <span>Enviar a:</span>
                                        <p>{truncateString(mainAddress?.address, 18)}</p>
                                    </div>
                                </a>
                                
                                <div className="Header__middlebar--actions-action" id="openCart" onClick={() => openModalCart()}>
                                    <img src={shoppingCartPlus} alt="" />
                                    <div className="counter"><span>{totalStock}</span></div>
                                </div>
                                <div className={`Header__middlebar--accountModal ${showAccountModal ? 'accountActive' : ''}`}>
                                    <div className="Header__middlebar--accountModal-title">
                                        <h2>Llegaste al paraíso</h2>
                                    </div>
                                    <div className="Header__middlebar--accountModal-description">
                                        <p>Regístrate para obtener grandes descuentos</p>
                                    </div>
                                    <div className="Header__middlebar--accountModal-buttons">
                                        <button onClick={ ()=> setShowAccountModal(false)}><a href="/login">Iniciar Sesión</a></button>
                                        <button onClick={ ()=> setShowAccountModal(false)}><a href="/register">Crear Cuenta</a></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                className={`Header__navbar ${mobileBarsClasses}`}
            >
                <div className="Header__navbar--container">
                    <div 
                        className="Header__navbar--mobileBars"
                        
                    >
                        <img src={menuIcon} alt="" onClick={()=>{ setShowHeaderMobile(true); document.body.style.overflow = 'hidden';}} />
                        <SearchInput view={"mobileSearch"} query={searchQuery} onSearch={handleSearchChange}/>
                    </div>
                </div>
            </div>
        </div>
        <div className="Header__barBottom">
            <a href="/my-account" className="Header__middlebar--actions-action HeaderAddressMobile" id="HeaderAddress">
                <img style={{width: '27px'}} src={address} alt="" />
                {mainAddress ? (
                    <div>
                        <span>Enviar a:</span>
                        <p>{truncateString(mainAddress.address, 50)}</p>
                    </div>
                ) : (
                    <div>
                        <span>Enviar a:</span>
                        <p>Dirección no disponible</p>
                    </div>
                )}
            </a>
        </div>

        <FloatCart cartModal={cartModal?.payload} totalStock={totalStock}/>

        <div className={`floatCartCoupon ${isCartFloatCouponActive ? 'floatCartCouponActive' : ''}`}>
            <div className="floatCartCoupon__heading">
                <i className="fa-solid fa-arrow-right" onClick={handleFloatCouponDisplay}></i>
                <span>Aplicar Cupón</span>
            </div>
            <div className="floatCartCoupon__input">
                <input type="text" name="couponCartFloat" id="couponCartFloat" placeholder="Ingresar Código" />
            </div>
            <div className="floatCartCoupon__button">
                <button>Enviar</button>
            </div>
        </div>

        <div className={`HeaderMobile ${showHeaderMobile ? 'mobileHeaderActive' : ''}`}>
            <div className="HeaderMobile__top">
                <div className="HeaderMobile__top--navs">
                    <div className="HeaderMobile__top--navs-nav headerNavActive">
                        <img src={barsMobile} alt="" />
                        <span>DondeLaNegra</span>
                    </div>
                </div>
                <div className="HeaderMobile__top--ubication">
                    <h4>
                        <div className="HeaderMobile__top--ubication-container">
                            <i
                                className="fa-solid fa-angle-left"
                                onClick={() => setActiveCategory("Categorías")}
                            ></i>
                            <span>{activeCategory}</span>
                        </div>
                        <img
                            src={closeIconWhite}
                            alt=""
                            onClick={() => {
                                setShowHeaderMobile(false);
                                document.body.style.overflow = 'initial';
                            }}
                        />
                    </h4>
                </div>
            </div>
            <div className="HeaderMobile__bodies">
                <div
                    className={`HeaderMobile__bodies--body ${
                        activeCategory === 'Categorías' ? 'headerBodyActive' : ''
                    }`}
                >
                    <ul>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/etiqueta-producto/negradays/">Negra Week⚡</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/packs/">Packs</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/whiskey/">Whiskey</a></li>
                        <li>
                            <a
                                href="/categoria-producto/vinos-y-espumantes/"
                                onClick={(e) => {
                                    if (e.target.classList.contains("fa-angle-down")) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                Vinos
                                <i
                                    className="fa-solid fa-angle-down"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setActiveCategory("Vinos y Espumantes");
                                    }}
                                ></i>
                            </a>
                        </li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/cervezas/">Cervezas</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/pisco/">Pisco</a></li>
                        <li>
                            <a
                                href="/categoria-producto/espumantes/"
                                onClick={(e) => {
                                    if (e.target.classList.contains("fa-angle-down")) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                Espumantes
                                <i
                                    className="fa-solid fa-angle-down"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setActiveCategory("Espumantes");
                                    }}
                                ></i>
                            </a>
                        </li>
                        
                        
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/gin/">Gin</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/ron/">Ron</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/tequilas/">Tequila</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/vodka/">Vodka</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/bebidas/">Bebidas y Energéticas</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/otros-licores/">Otros Licores</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/ocb-y-clipper/">OCB y Clipper</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/club-de-socios/">Club La Negra</a></li>
                    
                    </ul>
                </div>
                <div
                    className={`HeaderMobile__bodies--body ${
                        activeCategory === 'Espumantes' ? 'headerBodyActive' : ''
                    }`}
                >
                    <ul>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/espumantes/?filter_tipo-de-vino=brut">Brut</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/espumantes/?filter_tipo-de-vino=demisec">Demisec</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/espumantes/?filter_tipo-de-vino=moscato">Moscato</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/espumantes/?filter_tipo-de-vino=rose">Rose</a></li>
                    </ul>
                </div>
                <div
                    className={`HeaderMobile__bodies--body ${
                        activeCategory === 'Vinos y Espumantes' ? 'headerBodyActive' : ''
                    }`}
                >
                    <ul>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/grandes-vinos/">Grandes Vinos</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/cabernet-sauvignon/">Cabernet Sauvignon</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/carmenere/">Carmenere</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/chardonnay/">Chardonnay</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/merlot/">Merlot</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/sauvignon-blanc/">Sauvignon Blanc</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/malbec/">Malbec</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/ensamblaje/">Ensamblaje</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/syrah/">Syrah</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/otras-cepas/">Otras Cepas</a></li>
                        <li><a onClick={() => setShowHeaderMobile(false)} href="/categoria-producto/vinos-formatos-familiares/">Formatos Familiares</a></li>
                    </ul>
                </div>
            </div>
            <div className="HeaderMobile__bottom">
                <ul>
                    <li><a onClick={() => setShowHeaderMobile(false)} href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a onClick={() => setShowHeaderMobile(false)} href="#"><i className="fab fa-instagram"></i></a></li>
                    <li><a onClick={() => setShowHeaderMobile(false)} href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a onClick={() => setShowHeaderMobile(false)} href="#"><i className="fab fa-youtube"></i></a></li>
                </ul>
            </div>
        </div>


        <div className={`overlay ${isCartActive ? 'overlayActive' : ''}`} onClick={() => { handleCartDisplay(); setIsCartFloatCouponActive(false); }}></div>
        <AdultModal />
    </>
  )
}

export default Header;