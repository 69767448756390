import { useState, useEffect } from 'react';

const getLocalStorageItem = (key) => {
  if (typeof localStorage !== 'undefined') {
    return localStorage.getItem(key);
  }
  return null;
};

const dataProductsCart = getLocalStorageItem("products_cart");
const dataBillingCheckout = getLocalStorageItem("billing_checkout");
const dataUserLogin = getLocalStorageItem("user_login");
const dataCouponCode = getLocalStorageItem("all_data_coupon");

const initialState = {
  cart: JSON.parse(dataProductsCart) ?? [],
  product: [],
  billingCheckout: JSON.parse(dataBillingCheckout) ?? [],
  user: JSON.parse(dataUserLogin) ?? null,
  shippingLines: {},
  couponData: JSON.parse(dataCouponCode) ?? [],
  couponDiscount: 0,
  productSearched: [],
  billings: [],
  checkoutData: [], // Añadir checkoutData para manejar la información del checkout
};


const useInitialState = () => {
  const [state, setState] = useState(initialState);
  const [cartModal, setCartModal] = useState({ payload: false });
  const [menuMobile, setMenuMobile] = useState({ payload: false });
  const [loginModal, setLoginModal] = useState({ payload: false });
  const [registerModal, setRegisterModal] = useState({ payload: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');

    if (token) {
      fetchUser(token);
      fetchBillings(token);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUser = async (token) => {
    try {
      const response = await fetch(process.env.BASE_URL + '/user', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('user', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          user: data,
        }));
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBillings = async (token) => {
    try {
      const response = await fetch(process.env.BASE_URL + '/billings', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('billings', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          billings: data,
        }));
      }
    } catch (error) {
      console.error('Error fetching billings:', error);
    }
  };

  const login = async (email, password) => {
    try {
      const response = await fetch(process.env.BASE_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('auth_token', data.access_token);
        setState((prevState) => ({
          ...prevState,
          user: data.user,
        }));
        fetchBillings(data.access_token); // Fetch billings after login
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user');
    localStorage.removeItem('billings');
    localStorage.removeItem('discountAmount');
    localStorage.removeItem('checkoutData');
    localStorage.removeItem('address');
    localStorage.removeItem('products_cart');
    setState((prevState) => ({
      ...prevState,
      user: null,
      billings: [], // Limpiar facturaciones al cerrar sesión
    }));
  };

  const addBilling = async (billingData) => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await fetch(process.env.BASE_URL + '/billings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(billingData)
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('billings', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          billings: [...prevState.billings, data],
        }));
      } else {
        throw new Error('Error al guardar los datos de facturación');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateBilling = async (id, billingData) => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await fetch(process.env.BASE_URL + `/billings/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(billingData)
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('billings', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          billings: prevState.billings.map(billing => billing.id === id ? data : billing),
        }));
      } else {
        throw new Error('Error al actualizar los datos de facturación');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteBilling = async (id) => {
    try {
        const token = localStorage.getItem('auth_token');
        const response = await fetch(process.env.BASE_URL + `/billings/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            // Obtener los datos actuales del localStorage
            const billings = JSON.parse(localStorage.getItem('billings')) || [];

            // Filtrar el billing eliminado
            const updatedBillings = billings.filter(billing => billing.id !== id);

            // Actualizar el localStorage con los datos filtrados
            localStorage.setItem('billings', JSON.stringify(updatedBillings));

            // Actualizar el estado global
            setState((prevState) => ({
                ...prevState,
                billings: prevState.billings.filter(billing => billing.id !== id),
            }));
        } else {
            throw new Error('Error al eliminar los datos de facturación');
        }
      if (response.ok) {
        localStorage.setItem('billings', JSON.stringify(response.json()));
        setState((prevState) => ({
          ...prevState,
          billings: prevState.billings.filter(billing => billing.id !== id),
        }));
      } else {
        throw new Error('Error al eliminar los datos de facturación');
      }
    } catch (error) {
        console.error('Error:', error);
    }
};



  const updateAddress = async (id, addressesData) => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await fetch(process.env.BASE_URL + `/addresses/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(addressesData)
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('address', JSON.stringify(data));
        setState((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            addresses: prevState.user.addresses.map(address =>
              address.id === id ? data : address
            ),
          },
        }));
      } else {
        throw new Error('Error al actualizar la dirección');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const addToCart = (payload) => {
    const productsToAdd = Array.isArray(payload) ? payload : [payload];

    const updatedCart = productsToAdd.reduce((cart, incomingProduct) => {
        const existingProductIndex = cart.findIndex(item => item.product_id === incomingProduct.product_id);
        
        if (existingProductIndex !== -1) {
            // Actualizar el producto existente con el último stock
            const existingProduct = cart[existingProductIndex];
            const newQuantity = Math.min(
                existingProduct.quantity + incomingProduct.quantity, 
                incomingProduct.stock_quantity // Usar el stock del producto entrante (actualizado)
            );
            
            // Si el nuevo stock es 0, eliminar el producto del carrito
            if (newQuantity <= 0) {
                return cart.filter((_, index) => index !== existingProductIndex);
            }
            
            // Actualizar cantidad y stock
            return cart.map((item, index) => 
                index === existingProductIndex ? { 
                    ...item, 
                    quantity: newQuantity,
                    stock_quantity: incomingProduct.stock_quantity // Actualizar stock
                } : item
            );
        } else {
            // Solo agregar si hay stock disponible
            return incomingProduct.stock_quantity > 0 
                ? [...cart, { ...incomingProduct }] 
                : cart;
        }
    }, [...state.cart]); // Usar copia del carrito actual

    // Actualizar estado y almacenamiento
    setState({
        ...state,
        cart: updatedCart,
    });
    localStorage.setItem("products_cart", JSON.stringify(updatedCart));

    // Actualizar checkoutData
    const orderData = JSON.parse(localStorage.getItem("checkoutData")) || {};
    orderData.products = updatedCart;
    localStorage.setItem("checkoutData", JSON.stringify(orderData));
  };

  const modalCart = (payload) => {
    setCartModal({
      ...cartModal,
      payload,
    });
  };

  const modalMenuMobile = (payload) => {
    setMenuMobile({
      ...menuMobile,
      payload,
    });
  };

  const modalLogin = (payload) => {
    setLoginModal({
      ...loginModal,
      payload,
    });
  };

  const modalRegister = (payload) => {
    setRegisterModal({
      ...registerModal,
      payload,
    });
  };

  const removeFromCart = (payload) => {
    const newCart = [...state.cart];
    const filterCart = newCart.filter(items => items.product_id !== payload.product_id);
    localStorage.setItem("products_cart", JSON.stringify(filterCart));
    setState({
      ...state,
      cart: filterCart,
    });
    window.localStorage.removeItem('coupon_discount');

    // Actualiza checkoutData si existe
    let orderData = JSON.parse(localStorage.getItem("checkoutData")) || {};
    orderData.products = filterCart;
    localStorage.setItem("checkoutData", JSON.stringify(orderData));
  };

  const removeFromCartMultiple = (payloads) => {
    const idsToRemove = payloads.map(payload => payload.product_id);
    const newCart = state.cart.filter(item => !idsToRemove.includes(item.product_id));
    localStorage.setItem("products_cart", JSON.stringify(newCart));
    setState({
      ...state,
      cart: newCart,
    });
    window.localStorage.removeItem('coupon_discount');

     // Actualiza checkoutData si existe
     let orderData = JSON.parse(localStorage.getItem("checkoutData")) || {};
     orderData.products = newCart;
     localStorage.setItem("checkoutData", JSON.stringify(orderData));
  };

  const getProductBySlug = (payload) => {
    setState({
      ...state,
      product: [...state.product, payload],
    });
  };

  const addCheckoutData = (checkoutData) => {
    setState({
        ...state,
        checkoutData: checkoutData, // Guardamos los datos en el estado global
    });
};


  const addCouponData = (payload) => {
    setState({
      ...state,
      couponData: payload,
    });
    localStorage.setItem("all_data_coupon", JSON.stringify(payload));
  };

  const addCouponDiscount = (payload) => {
    setState({
      ...state,
      couponDiscount: payload,
    });
    localStorage.setItem("coupon_discount", JSON.stringify(payload));
  };

  const addUserLogin = (payload) => {
    setState({
      ...state,
      user: payload,
    });
    localStorage.setItem("user_login", JSON.stringify(payload));
  };

  const addShippingCost = (payload) => {
    const shippingCostAsInteger = Number(Number(payload.total).toFixed(0));
    setState({
      ...state,
      shippingLines: {
        title: payload.title,
        total: shippingCostAsInteger,
      },
    });
  };

  const addProductSearched = (payload) => {
    setState({
      ...state,
      productSearched: payload,
    });
  };

  const updateStockInCart = (payload) => {
    // Copiar el carrito actual del estado
    const updatedCart = [...state.cart];

    // Buscar el producto a actualizar en el carrito
    const productToUpdate = updatedCart.find(item => item.product_id === payload.product_id);

    if (productToUpdate) {
        // Validar que la cantidad no supere el stock disponible
        const newQuantity = Math.min(payload.quantity, productToUpdate.stock_quantity);
        
        if (newQuantity !== payload.quantity) {
            // alert(`No puedes agregar más de ${productToUpdate.stock_quantity} unidades de ${productToUpdate.name}`);
        }

        productToUpdate.quantity = newQuantity;
    }

    // Actualizar el estado con el nuevo carrito
    setState({
        ...state,
        cart: updatedCart,
    });

    // Guardar el carrito actualizado en el localStorage
    // dispatch({ type: 'UPDATE_CART', payload: updatedCart });
    localStorage.setItem("products_cart", JSON.stringify(updatedCart));

    // Actualizar checkoutData si existe
    let orderData = JSON.parse(localStorage.getItem("checkoutData")) || {};
    orderData.products = updatedCart;
    localStorage.setItem("checkoutData", JSON.stringify(orderData));
};




  return {
    state,
    cartModal,
    loginModal,
    registerModal,
    menuMobile,
    loading,
    addToCart,
    modalCart,
    removeFromCart,
    getProductBySlug,
    modalLogin,
    modalRegister,
    addCheckoutData,
    addUserLogin,
    addShippingCost,
    addCouponData,
    addCouponDiscount,
    addProductSearched,
    modalMenuMobile,
    updateStockInCart,
    removeFromCartMultiple,
    login,
    logout,
    addBilling,
    updateBilling,
    deleteBilling,
    updateAddress,
    setState, // Asegúrate de exportar setState
  };
};

export default useInitialState;
