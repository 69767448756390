import React, { useEffect, useState } from 'react';
import modalIco from '../images/logo-lanegra.png';
import '../css/components/AdultModal.css';
import { Link } from 'gatsby';


function AdultModal() {

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      const checkModalCookie = () => {
        return document.cookie.split(';').some((cookie) => cookie.trim().startsWith('modalShown=true'));
      };
  
      const showModal = () => {
        if (!checkModalCookie()) {
          setShowModal(true);
        }
      };
  
      const setModalCookie = () => {
        document.cookie = 'modalShown=true; path=/';
        setShowModal(false);
      };
  
      showModal();
  
      document.getElementById('siBtn').addEventListener('click', setModalCookie);
  
      return () => {
        document.getElementById('siBtn').removeEventListener('click', setModalCookie);
      };
    }, []);

  return (
    <div className={`adultModal ${showModal ? 'adultModalActive' : ''}`}>
        <div className="adultModal__container">
            <div className="adultModal__icon">
                <img src={modalIco} alt="" />
            </div>
            <div className="adultModal__title">
                <h2>Te damos la bienvenida</h2>
            </div>
            <div className="adultModal__subtitle">
                <p>Para entrar al sitio debes cumplir con la mayoría de edad.</p>
                <p>¿Tienes más de 18 años?</p>
            </div>
            <div className="adultModal__buttons">
                <button id="siBtn">Sí, quiero descubrir la felicidad</button>
            </div>
            <div className="adultModal__text">
                <p>Entrando a nuestro sitio estás aceptando nuestros  <br /><Link to="/terms-and-conditions/">Términos y Condiciones</Link> y <Link to="/terms-and-conditions/">Políticas de privacidad.</Link></p>
            </div>
        </div>
    </div>
  )
}

export { AdultModal }