import React, {useState, useContext} from "react";
import {MyContext} from '../context/AppContext';
import axios from "axios"; // Asegúrate de tener axios instalado
import productImage from '../images/productHoverImage.jpg';
import discountIco from '../images/discount.png';
import '../css/components/FloatCart.css';

function FloatCart({cartModal, totalStock}) {
    
    const { state, modalCart, removeFromCart, updateStockInCart } = useContext(MyContext);
    const [isCartActive, setIsCartActive] = useState(false);
    const [isCartFloatCouponActive, setIsCartFloatCouponActive] = useState(false);
    const [alertType, setAlertType] = useState(null);
    const [couponValue, setCuponValue] = useState("");
    const [discountAmount, setDiscountAmount] = useState(0); // Almacena el descuento aplicado
    const [errorMessage, setErrorMessage] = useState(""); // Almacena errores específicos del cupón
    const isAuthenticated = !!state.user; // Añadir esta línea para obtener estado de autenticación
    const getLocalStorageItem = (key) => {
        if (typeof localStorage !== 'undefined') {
            return localStorage.getItem(key);
        }
        return null;
    };
    const dataProductsCart = JSON.parse(getLocalStorageItem("products_cart"));
    const discountCoupon = JSON.parse(getLocalStorageItem("couponData")) || null;
    const sumSubTotal = () => {
        const reducer = (acc, item) => {
            const price = isAuthenticated && item.sale_price ? item.sale_price : item.price;
            return acc + Number(price) * Number(item.quantity);
        };
        return state.cart.reduce(reducer, 0);
    };

	const sumTotal = () => {
        const reducer = (acc, item) => {
            const price = isAuthenticated && item.sale_price ? item.sale_price : item.price;
            return acc + Number(price) * Number(item.quantity);
        };
        const total = state.cart.reduce(reducer, 0);
        let discount =  0; // Obtiene el descuento de localStorage
        if (discountCoupon) {
            discount = Number(discountCoupon?.amount).toFixed(0);
        }
        return total - discount;
    };
    // console.log(hasGreenTag)
    const sumTotalDiscount = () => {
        return sumTotal() * 0.07;
	}
    const closeModal = () => {
        modalCart(false);
        state.productSearched = [];
        // document.getElementById("search").value = "";
    }
    const handleRemove = product => {
        // const gtmData = {
        //     event: "remove_cart",
        //     pagePath: "cart_modal",
        //     pageTitle: "",
        //     additionalData: {
        //         ecommerce: {
        //             items: [{  // Lista de productos
        //                 item_name: product.name, // El nombre o ID es requerido.
        //                 item_id: product.sku,  // ID del producto.
        //                 price: product.price,  // Precio del producto.
        //                 item_brand: product.brand,  // Marca del producto.
        //                 item_category: product.category,  // Categoría general del producto.
        //                 quantity: product.quantity,  // Cantidad del producto.
        //                 item_SKU: product.sku,
        //             }]
                    
        //         }
        //     }
        // };
        // GTMDataLayerPusher(gtmData);
        removeFromCart(product);
        // localStorage.setItem("products_cart", JSON.stringify(state.cart));
        // console.log(state.cart)
    };
    const handleCartDisplay = () => {
        setIsCartActive(!isCartActive);
        if (isCartActive == true) {
            document.body.style.overflow = "initial";
        } else {
            document.body.style.overflow = "hidden";
        }
    };
    const handleUpdateQuantity = (internal_id, quantity) => {
        const productCart = {
            product_id: internal_id,
            quantity: quantity,

        }
        // console.log(productCart)
        updateStockInCart(productCart)
    }
 
    const handleFloatCouponDisplay = () => {
        setIsCartFloatCouponActive(!isCartFloatCouponActive);
    };

    const comprobateCoupon = () => {
        if (couponValue === "CUPON1") {
            setAlertType("good"); // Muestra alerta exitosa
        } else {
            setAlertType("bad"); // Muestra alerta de error
        }
        console.log(alertType)
        setIsCartFloatCouponActive(false);
        setTimeout(() => {
            setAlertType("");
          }, 4000);
    }

    const handleInputChange = (e) => {
        setCuponValue(e.target.value); // Actualiza el estado del valor del input
        // console.log(couponValue)
    };
    
    // Validar y aplicar el cupón
    const applyCoupon = async () => {
        try {
            const cartProducts = state.cart.map((item) => item.product_id); // IDs de productos en el carrito
            const cartTotal = sumTotal(); // Total actual del carrito
    
            const response = await axios.post(process.env.BASE_URL + "/check-coupon", {
                code: couponValue, // Código del cupón ingresado
                user_id: state.user?.id || null, // ID del usuario (puedes cambiar este valor según sea necesario)
                cart_total: cartTotal,
                products: cartProducts,
                categories: [], // Agrega las categorías necesarias si aplica
            });
    
            const { message, discount } = response.data;
            const couponData = {
                code: couponValue,
                amount: discount
            }
            setDiscountAmount(Number(discount).toFixed(0)); // Aplica el descuento retornado
            localStorage.setItem("couponData", JSON.stringify(couponData)); // Almacena el descuento en localStorage
    
            setAlertType("good"); // Muestra la alerta de éxito
            setTimeout(() => setAlertType(null), 2000);
            setErrorMessage(""); // Limpia los errores
            handleFloatCouponDisplay(); // Cierra el modal de cupón
        } catch (error) {
            setDiscountAmount(0); // Asegúrate de no aplicar descuento
            localStorage.removeItem("couponData"); // Elimina el descuento si la validación falla
            setAlertType("bad"); // Muestra la alerta de error
            setErrorMessage(error.response?.data?.message || "Error al validar el cupón.");
            setTimeout(() => setAlertType(null), 2000);
        }
    };
    
    const handleRemoveCoupon = () => {
        localStorage.removeItem("couponData");
    };
    
  return (
    <>
    <div className={`floatCart ${cartModal ? 'cartActive' : ''}`}>
        <div className="floatCart__top">
            <div className="floatCart__top--container">
                <div className="floatCart__top--counter">
                    <div className="floatCart__top--counter-icon">
                        <h4>{totalStock}</h4>
                    </div>
                    <div className="floatCart__top--counter-text">
                        <h4>Tu Carrito</h4>
                    </div>
                </div>
                <div className="floatCart__top--close" onClick={closeModal}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
            </div>
        </div>
        <div className="floatCart__body">
            <div className="floatCart__body--table">
            {!!dataProductsCart && dataProductsCart.map((product, index) => {
                // console.log(product)
                return (
                    <div className="floatCart__body--product" key={index}>
                        <div className="floatCart__body--product-image">
                            <img src={product.image} alt="" />
                        </div>
                        <div className="floatCart__body--product-details">
                            <div className="floatCart__body--product-counter">
                                <p>{product.total_sales}+ Ya llevaron este producto</p>
                            </div>
                            <div className="floatCart__body--product-name">
                                <h4>{product.name.substring(0, 30)}...</h4>
                                <div className="floatCart__body--product-remove" >
                                    <i className="fa-regular fa-trash-can" onClick={() => handleRemove(product)}></i>
                                </div>
                            </div>
                            <div className="floatCart__body--product-price">
                                <p>${new Intl.NumberFormat('de-DE').format(Math.round(
                                    isAuthenticated && product.sale_price 
                                        ? product.sale_price 
                                        : product.price
                                ))}</p>
                            </div>
                            <div className="floatCart__body--product-quantity">
                                <div className="floatCart__body--product-amount">
                                    <div className="floatCart__body--product-minus" onClick={() => {product.quantity > 1 ? handleUpdateQuantity(product.product_id, product.quantity - 1) : handleUpdateQuantity(product.product_id, product.quantity + 0)}}>
                                        -
                                    </div>
                                    <div className="floatCart__body--product-input">
                                        <input type="number" name="quantity" id="quantity" value={product.quantity} min="1" />
                                    </div>
                                    <div className="floatCart__body--product-plus" onClick={() => {handleUpdateQuantity(product.product_id, product.quantity + 1)}}>
                                        +
                                    </div>
                                </div>
                                <div className="floatCart__body--product-total">
                                    <bdi>${new Intl.NumberFormat('de-DE').format(Math.round(isAuthenticated && product.sale_price 
                                        ? product.sale_price 
                                        : product.price) * product.quantity)}</bdi>
                                </div>
                            </div>
                        </div>
                    </div>
                )})}
            </div>
        </div>
        <div className="floatCart__bottom">
            <div className="floatCart__bottom--coupon" onClick={handleFloatCouponDisplay}>
            {discountCoupon ? (
                <div className="floatCart__bottom--coupon-active">
                    <span>
                        <img src={discountIco} alt="" />
                        Cupón: {discountCoupon?.code}{" "}
                        <i
                            className="fas fa-times remove-coupon-icon"
                            onClick={handleRemoveCoupon}
                            style={{ cursor: "pointer", color: "red", fontSize: "18px", paddingTop: "3px" }}
                        ></i>
                    </span>
                    
                </div>
            ) : (
                <>
                    <img src={discountIco} alt="" />
                    <p>¿Tienes un cupón de descuento?</p>
                </>
            )}
            </div>
            {discountCoupon && (
            <div className="floatCart__bottom--subtotal">
                <h4>Subtotal</h4>
                <p>${new Intl.NumberFormat('de-DE').format(sumSubTotal().toFixed(0))}</p>
            </div>
            )}
            {discountCoupon && (
                <div className="floatCart__bottom--subtotal">
                    <h4>Descuento</h4>
                    <p>${new Intl.NumberFormat('de-DE').format(Number(JSON.parse(localStorage.getItem("couponData")).amount))}</p>
                </div>
            )}
            {/* <div className="floatCart__bottom--shipping">
                <h4>Envió</h4>
            </div> */}
            <div className="floatCart__bottom--total">
                <h4>Total</h4>
                <p>${new Intl.NumberFormat('de-DE').format(sumTotal().toFixed(0))}</p>
            </div>
            <div className="floatCart__bottom--text">
                <p>Para conocer el costo de envío, proceda al pago.</p>
            </div>
            <div className="floatCart__bottom--buttons">
                <button>Volver</button>
                <a onClick={closeModal} href={"/endcart"}>Ver Carrito</a >
            </div>
            <div className="floatCart__bottom--button">
                <a onClick={closeModal} href="/checkout">Pagar</a>
            </div>
        </div>
        <div className={`floatCartCoupon ${isCartFloatCouponActive ? 'floatCartCouponActive' : ''}`}>
            <div className="floatCartCoupon__heading">
                <i className="fa-solid fa-arrow-right" onClick={handleFloatCouponDisplay}></i>
                <span>Aplicar Cupón</span>
            </div>
            <div className="floatCartCoupon__input">
                <input
                    type="text"
                    name="couponCartFloat"
                    id="couponCartFloat"
                    placeholder="Ingresar Código"
                    value={couponValue} // Vincula el estado al valor del input
                    onChange={handleInputChange} // Actualiza el estado cuando se escribe en el input
                    onKeyDown={(e) => {
                        if (e.key === "Enter") applyCoupon();
                    }}
                />
            </div>
            <div className="floatCartCoupon__button">
            <button onClick={applyCoupon}>Enviar</button>
            </div>
            {/* Alertas */}
            {alertType && (
                <div
                    className={`floatCartAlert ${
                        alertType === "good" ? "floatCartAlertGoodActive" : "floatCartAlertBadActive"
                    }`}
                >
                    {alertType === "bad" ? (
                        <span>
                            <i className="fa-solid fa-xmark"></i> {errorMessage || "Cupón inválido."}
                        </span>
                    ) : (
                        <span>
                            <i className="fa-solid fa-circle-check"></i> ¡Cupón aplicado correctamente!
                        </span>
                    )}
                </div>
            )}
        </div>
        {/* 
            - floatCartAlertGoodActive poner esta clase cuando se active la alerta exitosa
            - floatCartAlertBadActive poner esta clase cuando se active la alerta erronea
        */}
        <div className={`floatCartAlert ${alertType === "good" ? "floatCartAlertGoodActive" : ""} ${alertType === "bad" ? "floatCartAlertBadActive" : ""}`}>
            {alertType === "bad" && (
                <span className="notFoundCouponText">
                    <i className="fa-solid fa-xmark"></i> ¡El cupón «{couponValue}» no existe!
                </span>
            )}
            {alertType === "good" && (
                <span className="FoundCouponText">
                    <i className="fa-solid fa-circle-check"></i> {couponValue} aplicado correctamente
                </span>
            )}
        </div>
    </div>
    <div onClick={closeModal} className={`${cartModal ? 'floatCart__overlay' : ''}`}>

    </div>
    </>
  )
}

export { FloatCart }