import React, { useState, useEffect } from 'react';
import ProductCard from '../../components/ProductCard';
import '../../css/components/ProductCategories/ProductCategories.css'
import filterIcon from "../../images/filters.png"
import filterIconMobile from "../../images/filterWhite.png"
import closeIcon from "../../images/close.png"
import closeIconWhite from "../../images/closeWhite.png"
import nextArrow from "../../images/nextArrow.png"
import Pagination from '../Pagination';
// const API = process.env.REACT_APP_CRM_BASE_URL + "/products-search";

const LayoutSearch = ({products}) => {
    
    // console.log(products)
    const productsStock = products;
    const [sortedProducts, setSortedProducts] = useState(products);
    const [isFilterModalActive, setIsFilterModalActive] = useState(false);
    const [isOrderByModalActive, setIsOrderByModalActive] = useState(false);
    const [activeFilter, setActiveFilter] = useState('Filtros');
    const [filters, setFilters] = useState({}); // Filtros seleccionados
    const [pendingFilters, setPendingFilters] = useState({}); // Filtros en el modal
    const [sortOption, setSortOption] = useState('');
    const [pendingSortOption, setPendingSortOption] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(50); // Cantidad de productos por página
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProducts = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
    // Función para cambiar de página
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, [currentPage]);
    const handleFilterChange = (attributeName, option) => {
        setPendingFilters(prevFilters => {
            const newFilters = { ...prevFilters };
            if (!newFilters[attributeName]) {
                newFilters[attributeName] = [];
            }
    
            // Alternar la selección del filtro
            if (newFilters[attributeName].includes(option)) {
                newFilters[attributeName] = newFilters[attributeName].filter(item => item !== option);
            } else {
                newFilters[attributeName].push(option);
            }
    
            return newFilters;
        });
    };
    
    const combinedAttributes = products.reduce((acc, product) => {
        product.attributes.forEach(attr => {
            const existingAttr = acc.find(a => a.name === attr.name);
            if (existingAttr) {
                existingAttr.options = [...new Set([...existingAttr.options, ...attr.options])];
            } else {
                acc.push({ ...attr });
            }
        });
        return acc;
    }, []);
    
    
    const applyFilters = () => {
        setFilters(pendingFilters);
    
        let filtered = products;
    
        Object.keys(pendingFilters).forEach(attribute => {
            const options = pendingFilters[attribute];
            if (options.length > 0) {
                filtered = filtered.filter(product =>
                    product.attributes.some(attr =>
                        attr.name === attribute && options.some(option => attr.options.includes(option))
                    )
                );
            }
        });
    
        // Ordenar los productos agotados al final
        filtered.sort((a, b) => {
            const aStock = a.stock_quantity ?? 0;
            const bStock = b.stock_quantity ?? 0;
    
            if (aStock > 0 && bStock <= 0) return -1;
            if (aStock <= 0 && bStock > 0) return 1;
            return 0;
        });
    
        setSortedProducts(filtered);
        setIsFilterModalActive(false);
    };
    
    const handleSortChange = (option) => {
        setPendingSortOption(option);
    };
    const applySort = () => {
        setSortOption(pendingSortOption);

        let sorted = [...sortedProducts];
        switch (pendingSortOption) {
            case 'recent':
                sorted.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
                break;
            case 'bestSelling':
                sorted.sort((a, b) => b.total_sales - a.total_sales);
                break;
            case 'priceHighToLow':
                sorted.sort((a, b) => b.sale_price - a.sale_price);
                break;
            case 'priceLowToHigh':
                sorted.sort((a, b) => a.sale_price - b.sale_price);
                break;
            case 'alphabetical':
                sorted.sort((a, b) => a.name.localeCompare(b.name)); // Ordenar por nombre A-Z
                break;
            default:
                break;
        }
        sorted.sort((a, b) => {
            const aStock = a.stock_quantity ?? 0; // Tratar null como 0
            const bStock = b.stock_quantity ?? 0;

            // Priorizar productos con stock > 0
            if (aStock > 0 && bStock <= 0) return -1;
            if (aStock <= 0 && bStock > 0) return 1;

            // Mantener el orden relativo si ambos tienen stock iguales
            return 0;
        });
        setSortedProducts(sorted);
        setIsOrderByModalActive(false);
    };

    const clearFilters = () => {
        setPendingFilters({});
        setFilters({});
        setSortedProducts(products);
    };
    
    const clearSort = () => {
        setPendingSortOption('');
        setSortOption('');
        setSortedProducts(products);
    };
    
    useEffect(() => {
        if (products) {
            const sorted = [...products].sort((a, b) => {
                const aStock = a.stock_quantity == null ? 0 : a.stock_quantity; // Tratar null como 0
                const bStock = b.stock_quantity == null ? 0 : b.stock_quantity; // Tratar null como 0
    
                if (aStock > 0 && bStock <= 0) return -1;
                if (aStock <= 0 && bStock > 0) return 1;
    
                return 0;
            });
            setSortedProducts(sorted);
        }
    }, [products]); // Se ejecuta cada vez que cambian los productos
    
    // console.log(sortedProducts)
    const [currentItems, setCurrentItems] = useState([]);
    const [modalFilterBy, setModalFilterBy] = useState(false);
    // Estado para el rango de precio
    // const [minPrice, setMinPrice] = useState(0);
    // const [maxPrice, setMaxPrice] = useState(200000);

     // Cantidad máxima de páginas a mostrar en la paginación
     const maxPageNumbersToShow = 5; 

    // Resetear el scroll al inicio de la página al cambiar la página
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Desplaza suavemente hacia arriba
    }, [currentPage]); // Cada vez que cambie la página
    
     // Lógica para generar los números de página a mostrar en la paginación
     const getPageNumbersToShow = () => {
         const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
         const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);
 
         // Calculamos el rango de números de página a mostrar
         let startPage = Math.max(1, currentPage - halfMaxPageNumbersToShow);
         let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
 
         // Si estamos cerca del principio, ajustamos el final de la lista
         if (startPage === 1) {
             endPage = Math.min(maxPageNumbersToShow, totalPages);
         }
         // Si estamos cerca del final, ajustamos el inicio de la lista
         else if (endPage === totalPages) {
             startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
         }
 
         // Generamos los números de página a mostrar
         const pageNumbersToShow = [];
         for (let i = startPage; i <= endPage; i++) {
             pageNumbersToShow.push(i);
         }
 
         return pageNumbersToShow;
     };
 
     // Generamos los números de página a mostrar
     const pageNumbersToShow = getPageNumbersToShow();
 
     // Renderizamos los números de página
     const renderPageNumbers = pageNumbersToShow.map(number => (
         <button 
             key={number} 
             onClick={() => currentPage !== number && setCurrentPage(number)} // Solo permite clickear si no es la página actual
             className={currentPage === number ? "active" : ""}
             disabled={currentPage === number}
             style={{ cursor: currentPage === number ? "default" : "pointer" }} // Cambia el cursor si es la página actual
         >
             {number}
         </button>
     ));

    useEffect(() => {
        // console.log(sortedProducts)
        if (sortedProducts) {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            const newCurrentItems = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(newCurrentItems);
        }
    }, [currentPage, sortedProducts, itemsPerPage]);
    
    if (productsStock && productsStock.length > 0) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(sortedProducts.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }
        let paginationItems;
        if (pageNumbers.length <= 5) {
          paginationItems = pageNumbers.map(number => (
            <button key={number} onClick={() => setCurrentPage(number)}>
              {number}
            </button>
          ));
        } else { 
          paginationItems = (
            <>
              {/* <div className='productsCategories__right--jumpPage-pageNumber pageActive' onClick={() => setCurrentPage(1)}>1</div> */}
    
              {currentPage > 4 && <div className='productsCategories__right--jumpPage-pageNumber'>...</div>}
              {currentPage > 1 && (
                 <div onClick={() => setCurrentPage(currentPage - 1)} class="productsCategories__right--jumpPage-pageNumber pageActive">
                    {currentPage - 1}
                </div>
              )}
              <div class="productsCategories__right--jumpPage-pageNumber pageDisabled" disabled>{currentPage}</div>
              {currentPage < pageNumbers.length - 1 && (
                <div onClick={() => setCurrentPage(currentPage + 1)} class="productsCategories__right--jumpPage-pageNumber pageActive">
                    {currentPage + 1}
                </div>
              )}
              {currentPage < pageNumbers.length - 2 && <div className='productsCategories__right--jumpPage-pageNumber'>...</div>}
              <div className='productsCategories__right--jumpPage-pageNumber pageActive' onClick={() => setCurrentPage(pageNumbers.length)}>
                {pageNumbers.length}
              </div>
            </>
          );
        }
        return (
            <div className="Content__productCategories">
                <div style={{maxWidth: "100%"}} className="">
                    <div className="Content__productCategories--title">
                        <h2>¡Únete <strong>GRATIS</strong> al <strong>Club La Negra</strong> y accede a nuestros mejores descuentos! <a href="#">Empieza aquí</a></h2>
                    </div>
                    <div className="Content__productCategories--grid">
                        {/* <div className="Content__productCategories--filters">
                            <div className="Content__productCategories--filters-filter">
                                <h4>Categoría</h4>
                                <ul>
                                    <li><Link to="/categories">Cervezas</Link></li>
                                </ul>
                            </div>
                            <div className="Content__productCategories--filters-filter">
                                <h4>Opciones</h4>
                                <ul>
                                    <li><Link to="/categories">Botellín <span>X</span></Link></li>
                                    <li><Link to="/categories">Lata <span>X</span></Link></li>
                                    <li><Link to="/categories">Sin Alcohol</Link></li>
                                </ul>
                            </div>
                            <div className="Content__productCategories--filters-filter">
                                <h4>Formato</h4>
                                <ul>
                                    <li><Link to="/categories">330cc</Link></li>
                                    <li><Link to="/categories">355cc</Link></li>
                                    <li><Link to="/categories">470cc</Link></li>
                                    <li><Link to="/categories">500cc</Link></li>
                                    <li><Link to="/categories">650cc</Link></li>
                                    <li><Link to="/categories">710cc</Link></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="Content__productCategories--heading">
                            <div className="Content__productCategories--breadcrumbs">
                                <span>Inicio / Gin</span>
                            </div>
                            <div className="Content__productCategories--filtersGroup">
                                <div className="Content__productCategories--filtersContainer">
                                    <div className="Content__productCategories--filters" onClick={() => setIsFilterModalActive(true)}>
                                        <h4>Filtrar por</h4>
                                        <img src={filterIcon} alt="" className="desktopIconFilter" />
                                        <img src={filterIconMobile} alt="" className="mobileIconFilter" />
                                    </div>
                                    {isFilterModalActive && (
                                        <div className={`Content__productCategories--filtersModal ${isFilterModalActive ? 'filtersModalActive' : ''}`}>
                                            <div className="Content__productCategories--filters-Heading">
                                                <h4>Filtros</h4>
                                                <img src={closeIcon} className="desktopCloseIcon" alt="" onClick={() => setIsFilterModalActive(false)} />
                                                <img src={closeIconWhite} className="mobileCloseIcon" alt="" onClick={() => setIsFilterModalActive(false)} />
                                            </div>
                                            <div className="Content__productCategories--filters-body">
                                                <div style={{paddingTop: "0"}} className="Content__productCategories--filters-content">
                                                    {combinedAttributes.map(attribute => (
                                                        <div style={{marginTop: "20px"}} key={attribute.id}>
                                                            <h4>{attribute.name}</h4>
                                                            <ul>
                                                                {attribute.options.map(option => (
                                                                    <li key={option}>
                                                                        <label className="customCheckboxContainer">
                                                                            <input
                                                                                type="checkbox"
                                                                                onChange={() => handleFilterChange(attribute.name, option)}
                                                                                checked={pendingFilters[attribute.name]?.includes(option) || false}
                                                                            />
                                                                            {option}
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="Content__productCategories--filters-footer">
                                                <button onClick={clearFilters}>Limpiar</button>
                                                <button id="aplicarButton" onClick={applyFilters}>Aplicar</button>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="Content__productCategories--orderBy">
                                    <label htmlFor="orderBy" onClick={() => setIsOrderByModalActive(true)}>
                                        Ordenar Por:
                                        <strong className="desktopIconFilter">
                                            {sortOption === 'recent' && 'Recien agregados'}
                                            {sortOption === 'bestSelling' && 'Más vendidos'}
                                            {sortOption === 'priceHighToLow' && 'Precio más alto al más bajo'}
                                            {sortOption === 'priceLowToHigh' && 'Precio más bajo al más alto'}
                                            {sortOption === 'alphabetical' && 'Abecedario A - Z'}
                                            {!sortOption && 'Seleccionar'}
                                        </strong>
                                        <img src={filterIconMobile} alt="" className="mobileIconFilter" />
                                    </label>
                                    <div className={`Content__productCategories--filtersModal ${isOrderByModalActive ? 'filtersModalActive' : ''}`}>
                                        <div className="Content__productCategories--filters-Heading">
                                            <h4>Ordenar por</h4>
                                            <img
                                                src={closeIcon}
                                                className="desktopCloseIcon"
                                                alt=""
                                                onClick={() => setIsOrderByModalActive(false)}
                                            />
                                            <img
                                                src={closeIconWhite}
                                                className="mobileCloseIcon"
                                                alt=""
                                                onClick={() => setIsOrderByModalActive(false)}
                                            />
                                        </div>
                                        <div className="Content__productCategories--filters-body">
                                            <div className="Content__productCategories--filters-content">
                                                <ul>
                                                    <li>
                                                        <label className="customCheckboxContainer">
                                                            <input
                                                                type="radio"
                                                                name="sort"
                                                                checked={pendingSortOption === 'bestSelling'}
                                                                onChange={() => handleSortChange('bestSelling')}
                                                            />
                                                            Más vendidos
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="customCheckboxContainer">
                                                            <input
                                                                type="radio"
                                                                name="sort"
                                                                checked={pendingSortOption === 'recent'}
                                                                onChange={() => handleSortChange('recent')}
                                                            />
                                                            Recien agregados
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="customCheckboxContainer">
                                                            <input
                                                                type="radio"
                                                                name="sort"
                                                                checked={pendingSortOption === 'priceHighToLow'}
                                                                onChange={() => handleSortChange('priceHighToLow')}
                                                            />
                                                            Precio más alto al más bajo
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="customCheckboxContainer">
                                                            <input
                                                                type="radio"
                                                                name="sort"
                                                                checked={pendingSortOption === 'priceLowToHigh'}
                                                                onChange={() => handleSortChange('priceLowToHigh')}
                                                            />
                                                            Precio más bajo al más alto
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label className="customCheckboxContainer">
                                                            <input
                                                                type="radio"
                                                                name="sort"
                                                                checked={pendingSortOption === 'alphabetical'}
                                                                onChange={() => handleSortChange('alphabetical')}
                                                            />
                                                            Abecedario A - Z
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="Content__productCategories--filters-footer">
                                            <button onClick={clearSort}>Limpiar</button>
                                            <button id="aplicarButton" onClick={applySort}>Aplicar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Content__productCategories--cards">
                            {currentProducts.map((product)=> {
                                return (
                                    <ProductCard key={product.id} product={product}/>
                                )
                            })}
                            <div className={`overlayProducts ${isFilterModalActive ? 'overlayActive' : ''}`} onClick={() => { setIsFilterModalActive(false); }}></div>
                            <div className={`overlayProducts ${isOrderByModalActive ? 'overlayActive' : ''}`} onClick={() => { setIsOrderByModalActive(false); }}></div>
                        </div>

                        {/* Nuevo paginador */}
                        {totalPages > 1 && (
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={setCurrentPage}
                                leftEdgePages={2}
                                rightEdgePages={2}
                                showLeftEllipsis={true}
                                showRightEllipsis={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default LayoutSearch;
