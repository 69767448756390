import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';

const Pagination = ({ 
  currentPage, 
  totalPages, 
  onPageChange
}) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const generatePageNumbers = () => {
    if (totalPages <= 7) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const firstTwo = [1, 2];
    const lastTwo = [totalPages - 1, totalPages];
    let middleThree = [];

    if (currentPage <= 3) {
      middleThree = [3, 4, 5];
    } else if (currentPage >= totalPages - 2) {
      middleThree = [totalPages - 4, totalPages - 3, totalPages - 2];
    } else {
      middleThree = [currentPage - 1, currentPage, currentPage + 1];
    }

    const combined = [...firstTwo, ...middleThree, ...lastTwo];
    const uniquePages = Array.from(new Set(combined));
    uniquePages.sort((a, b) => a - b);

    return uniquePages;
  };

  if (totalPages <= 1) return null;

  return (
    <div className="pagination-container">
      <button
        className="pagination-arrow"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <svg className="arrow prev-arrow" viewBox="0 0 24 24" width="18" height="18">
          <path fill="currentColor" d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/>
        </svg>
      </button>

      {generatePageNumbers().map((page) => (
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={`pagination-button ${currentPage === page ? 'active' : ''}`}
          disabled={currentPage === page}
        >
          {page}
        </button>
      ))}

      <button
        className="pagination-arrow"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <svg className="arrow" viewBox="0 0 24 24" width="18" height="18">
          <path fill="currentColor" d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
        </svg>
      </button>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;