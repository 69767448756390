import React, { useState, useEffect } from 'react';
import stringSimilarity from 'string-similarity';
import { navigate } from 'gatsby';
import search from '../../images/search.png';

export const SearchInput = ({ query, onSearch, view, clase }) => {
  const [searchTerm, setSearchTerm] = useState(query);

  function normalizeText(text) {
    // Convertir a minúsculas y eliminar solo acentos, pero mantener caracteres como "ñ"
    return text
      .toLowerCase()
      .replace(/[áàäâ]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .replace(/[ç]/g, 'c'); // Opcional: manejar otros caracteres específicos
  }
  
  // En tu componente de búsqueda
  const handleSearchChange = (e) => {
    const newQuery = normalizeText(e.target.value);
    setSearchTerm(newQuery); // Actualiza el estado local con la consulta normalizada
    onSearch(newQuery); // Realiza la búsqueda en tiempo real en la página de búsqueda
  };

  const handleSearchClick = () => {
    // Redirige a la página de búsqueda con el término de búsqueda como parámetro de consulta
    navigate(`/buscar/?q=${encodeURIComponent(searchTerm)}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Evita la recarga de la página al presionar Enter
      e.preventDefault();

      // Redirige a la página de búsqueda con el término de búsqueda como parámetro de consulta
      navigate(`/buscar/?q=${encodeURIComponent(searchTerm)}`);
    }
  };

  useEffect(() => {
    // Actualiza el estado local `searchTerm` cuando cambia la prop `query`
    setSearchTerm(query);
  }, [query]);

  return (
    <div className={view ? "Header__middlebar--search mobileSearch" : "Header__middlebar--search" + " " + clase}>
      <button onClick={handleSearchClick}><img src={search} alt="" /></button>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        placeholder="¿Qué Buscas?"
        style={{ fontSize: '16px' }}
      />
    </div>
  );
};
