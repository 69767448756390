import { useState, useEffect } from "react";

const useStockValidation = (productId) => {
    const [isOutOfStock, setIsOutOfStock] = useState(false);
    const [loading, setLoading] = useState(false);

    const validateStock = async (quantity) => {
        try {
            setLoading(true);
            const response = await fetch(`${process.env.BASE_URL}/api/product/check-stock/${productId}`);
            const data = await response.json();

            if (data.success && quantity <= data.stock_quantity) {
                setIsOutOfStock(false); // Hay stock suficiente
            } else {
                setIsOutOfStock(true); // No hay stock suficiente
            }
        } catch (error) {
            console.error("Error al validar el stock:", error);
            setIsOutOfStock(true); // Asumimos sin stock si hay un error
        } finally {
            setLoading(false);
        }
    };

    return { isOutOfStock, validateStock, loading };
};

export default useStockValidation;
