import React, { useState, useEffect } from 'react';
import '../css/components/ProductCard.css';
import productImage from '../images/productImage.jpg';
import productImageHover from '../images/productHoverImage.jpg';
import promoBanner from '../images/christmasPromo.jpg';
import shoppingBag from '../images/shopping-bag-movil.png';
import { Link } from 'gatsby';
import { MyContext } from '../context/AppContext';
import useStockValidation from '../utils/useStockValidation';
import checkIcon from '../images/cheque.png';
import warningIcon from '../images/Ico_agotado.png';

function ProductCard({ imageHover, product  }) {
  const { addToCart } = React.useContext(MyContext);
  const [quantity, setQuantity] = useState(1);
  const [isOutOfStock, setIsOutOfStock] = useState(product?.stock_quantity <= 0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAdded, setIsAdded] = useState(false);
  const [isCartText, setIsCartText] = useState('Agregar');
  const [imageCart, setImageCart] = useState(shoppingBag);

  const useMobileDetection = () => {
    const [isMobile, setIsMobile] = useState(false);
  
    useEffect(() => {
      if (typeof window !== 'undefined') { // Verificación SSR
        const checkMobile = () => {
          const mobile = window.matchMedia("(max-width: 768px)").matches;
          setIsMobile(mobile);
        };
        
        checkMobile();
        window.addEventListener('resize', checkMobile);
        
        return () => window.removeEventListener('resize', checkMobile);
      }
    }, []);
  
    return isMobile;
  };

  console.log(product);
  const decreaseQuantity = () => { 
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (product.stock_quantity != quantity && product.stock_quantity > quantity) {
      setQuantity(quantity + 1);
      // console.log(product)
      // console.log(quantity)
    }
  };

  // Función para obtener el usuario autenticado desde localStorage
  const getLocalStorageItem = (key) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem(key);
    }
    return null;
  };

  const user = JSON.parse(getLocalStorageItem("user"));
  const isAuthenticated = user; // Si hay un usuario en localStorage, está autenticado

  const getDisplayPrice = (product) => {
    if (isAuthenticated) {
      return product.sale_price ? parseFloat(product.sale_price) : parseFloat(product.price);
    }
    return parseFloat(product.price);
  };

  const formattedPrice = (price) => new Intl.NumberFormat('de-DE').format(price);

  const handleClick = async (item) => {
    try {
        setLoading(true);
        setErrorMessage("");
        setIsCartText('Verificando...');

        // Llamar a la API para verificar el stock
        const response = await fetch(`${process.env.BASE_URL}/product/check-stock/${item.internal_id}`);
        const data = await response.json();

        if (data.success) {
            // Si `stock_quantity` es null, asignar 0 como valor por defecto
            const stockQuantity = data.stock_quantity ?? 0;

            if (stockQuantity === 0) {
              // Si no hay stock, marcar como agotado
              setIsOutOfStock(true);
            } else if (quantity > stockQuantity) {
                // Informar al usuario si la cantidad excede el stock
                setErrorMessage(`Solo quedan ${stockQuantity} unidades disponibles.`);
            } else if (stockQuantity > 0) {
                // Hay stock disponible y cantidad válida
                setIsOutOfStock(false);
                const productCart = {
                    product_id: item.internal_id,
                    wordpress_id: item.id,
                    sku: item.sku,
                    name: item.name,
                    slug: item.slug,
                    price: item.price,
                    sale_price: item.sale_price,
                    image: process.env.BASE_FRONT + item.images[0]?.src,
                    description: item.description,
                    quantity: quantity,
                    tags: item.tags,
                    stock_quantity: stockQuantity,
                    total_sales: item.total_sales,
                    box: item.box,
                };
                addToCart(productCart);
                setIsCartText('Agregado');
                setImageCart(checkIcon);
                setIsAdded(true);

            } else {
                // No hay stock disponible
                setIsCartText('Agotado');
                setIsOutOfStock(true);
                setErrorMessage("Producto agotado.");
            }
        } else {
            // No hay información de stock
            setIsCartText('Agotado');
            setErrorMessage("No se pudo verificar el stock del producto.");
            setIsOutOfStock(true);
        }
    } catch (error) {
      setIsCartText('Agotado');
        // console.error("Error al verificar el stock:", error);
        setErrorMessage("Hubo un problema al verificar el stock. Por favor, inténtalo de nuevo.");
        setIsOutOfStock(true); // En caso de error, asumimos que no hay stock
    } finally {
        setLoading(false);
    }
  };


  const getPositionStyles = (positionType, styles) => {
    const { top, bottom, left, right } = styles;

    // Verificar si todos los valores son "0" o están vacíos
    const arePositionsEmpty =
      (!top || top === "0") &&
      (!bottom || bottom === "0") &&
      (!left || left === "0") &&
      (!right || right === "0");

    // Si no hay valores individuales, usar `position_type`
    if (arePositionsEmpty) {
      switch (positionType) {
        case "topLeft":
          return { top: "0", left: "0" };
        case "topRight":
          return { top: "0", right: "0" };
        case "bottomLeft":
          return { bottom: "0", left: "0" };
        case "bottomRight":
          return { bottom: "0", right: "0" };
        case "top":
          return { top: "0", left: "50%", transform: "translateX(-50%)" };
        case "bottom":
          return { 
            position: "absolute",
            bottom: "-3px",
            left: "0",
            right: "0",
            margin: "0 auto",
          };
        case "left":
          return { left: "0", top: "50%", transform: "translateY(-50%)" };
        case "right":
          return { right: "0", top: "50%", transform: "translateY(-50%)" };
        case "center":
          return { 
            position: "absolute",
            top: "50%",
            left: "0",
            right: "0",
            margin: "0 auto",
            transform: "translateY(-50%)",
          };
        default:
          return {}; // Estilos por defecto
      }
    }

      // Usar los valores explícitos si están definidos
      return {
        top: top && top !== "0" ? `${top}px` : "auto",
        bottom: bottom && bottom !== "0" ? `${bottom}px` : "auto",
        left: left && left !== "0" ? `${left}px` : "auto",
        right: right && right !== "0" ? `${right}px` : "auto",
      };
  };

  const isMobile = useMobileDetection();

  if (product) {
    return (
      <div className="Content__productCard categoriesProductCard">
        <a href={`/producto/${product.slug}`}>
          <div className="Content__productCard--image">

          

            <div className="Content__productCard--image-mainImage activeImage">
            {/* Mostrar insignias */}
            {product.insignias && product.insignias.length > 0 && (
              <div className="Content__productCard--insignias">
                {product.insignias.map((insignia, index) => {
                   const currentWidth = isMobile && insignia.width_mobile 
                   ? `${insignia.width_mobile}px` 
                   : `${insignia.width}px`;
                   const positionStyles = getPositionStyles(insignia.position_type, {
                    top: insignia.top,
                    bottom: insignia.bottom,
                    left: insignia.left,
                    right: insignia.right,
                  });

                  const containerStyles = {
                    position: 'absolute',
                    width: currentWidth === "0px" ? 'auto !important' : currentWidth,
                    height: 'fit-content',
                    ...positionStyles,
                    transform: `
                      rotateX(${insignia.rotation_x || 0}deg)
                      rotateY(${insignia.rotation_y || 0}deg)
                      rotateZ(${insignia.rotation_z || 0}deg)
                    `,
                    opacity: insignia.opacity ? `${insignia.opacity / 100} !important` : '1 !important',
                    marginLeft: insignia.margin_left ? `${insignia.margin_left}px !important` : '0 !important',
                    marginTop: insignia.margin_top ? `${insignia.margin_top}px !important` : '0 !important',
                  };

                  return (
                    <div key={index} className="insignia" style={containerStyles}>
                      <img
                        style={{
                          maxWidth: '100%',
                        }}
                        src={`${process.env.BASE_FRONT}/storage/media/${insignia.media_id}/${insignia.preview}`}
                        alt={insignia.name}
                      />
                    </div>
                  );
                })}
              </div>
              )}
              {imageHover ? (
               <img className="ImageHover" src={`${process.env.BASE_FRONT}/products/${product.recommended_image}`} alt="" />
              ) : (
                <img 
                    src={product.product_card_image 
                      ? `${process.env.BASE_FRONT + product.images[0]?.src}`
                      : process.env.BASE_FRONT + product.images[0].src} 
                    alt="" 
                  />
              )}
            </div>
            {imageHover && (
              <div className="Content__productCard--image-hoverImage">
                {imageHover ? (
                  <img className="ImageHover" src={`${process.env.BASE_FRONT + product.images[0]?.src}`} alt="" />
                ) : (
                  <img 
                    src={product.product_card_image 
                      ? `${process.env.BASE_FRONT + product.images[0]?.src}`
                      : process.env.BASE_FRONT + product.images[0].src} 
                    alt="" 
                  />
                )}
              </div>
            )}
          </div>
        </a>
        <div className="Content__productCard--details">
          <div className="Content__productCard--details-name">
            <a to={`/producto/${product.slug}`}>
              <h4>{product.name}</h4>
            </a>
          </div>
          <div className="Content__productCard--details-category">
            <p>Botella 750cc</p>
          </div>
          <div className="Content__productCard--details-prices">
              <div className="Content__productCard--details-clubPrice">
                <div className="productCard__details--clubPrice-percent">
                  <p>-{(((product.ref_price - product.sale_price) / product.ref_price) * 100).toFixed(0)}%</p>
                </div>
                <div className="productCard__details--clubPrice-price">
                  <p>${formattedPrice(product.sale_price)}</p>
                </div>
                <div className="productCard__details--clubPrice-ico">Club</div>
              </div>
              <div className="Content__productCard--details-regularPrice">
                <div className="productCard__details--regularPrice-percent">
                  <p>-{(((product.ref_price - product.price) / product.ref_price) * 100).toFixed(0)}%</p>
                </div>
                <div className="productCard__details--regularPrice-price">
                  <p>${formattedPrice(product.price)}</p>
                </div>
              </div>
            <div className="Content__productCard--details-refPrice">
              <del>Ref. ${formattedPrice(product.ref_price)}</del>
            </div>
          </div>

          <div className="Content__productCard--actions">
            <div className="Content__productCard--addToCart">
              <div className="Content__productCard--addToCart-minus" onClick={decreaseQuantity}>
                -
              </div>
              <div className="Content__productCard--addToCart-input">
                <input type="number" name="quantity" id="quantity" value={quantity} readOnly />
              </div>
              <div className="Content__productCard--addToCart-plus" onClick={increaseQuantity}>
                +
              </div>
            </div>
            <div className="Content__productCard--addToCartButton">
              {isOutOfStock ? (
                <button disabled className="product-card-button out-of-stock">
                  <img src={warningIcon} alt="Agotado" className="button-icon" />
                  <span>Agotado</span>
                </button>
              ) : (
                <button
                  onClick={() => handleClick(product)}
                  disabled={loading}
                  className={`product-card-button ${isAdded ? 'added' : ''}`}
                >
                  {loading ? (
                    <div className="loading-container">
                      <div className="spinner"></div>
                    </div>
                  ) : (
                    <>
                      <img src={imageCart} alt="Agregar" className="button-icon" />
                      <span>{isCartText}</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
            {errorMessage && (
                <div className="Content__productCard--errorMessage">
                    <p>{errorMessage}</p>
                </div>
            )}
        </div>
      </div>
    );
  }
 
}

export default ProductCard;
