exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-buscar-js": () => import("./../../../src/pages/buscar.js" /* webpackChunkName: "component---src-pages-buscar-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-checkout-copy-js": () => import("./../../../src/pages/checkout copy.js" /* webpackChunkName: "component---src-pages-checkout-copy-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-club-de-socios-js": () => import("./../../../src/pages/club-de-socios.js" /* webpackChunkName: "component---src-pages-club-de-socios-js" */),
  "component---src-pages-compra-realizada-jsx": () => import("./../../../src/pages/compra-realizada.jsx" /* webpackChunkName: "component---src-pages-compra-realizada-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datos-facturacion-create-jsx": () => import("./../../../src/pages/datos-facturacion-create.jsx" /* webpackChunkName: "component---src-pages-datos-facturacion-create-jsx" */),
  "component---src-pages-datos-facturacion-edit-jsx": () => import("./../../../src/pages/datos-facturacion-edit.jsx" /* webpackChunkName: "component---src-pages-datos-facturacion-edit-jsx" */),
  "component---src-pages-datos-facturacion-js": () => import("./../../../src/pages/datos-facturacion.js" /* webpackChunkName: "component---src-pages-datos-facturacion-js" */),
  "component---src-pages-endcart-js": () => import("./../../../src/pages/endcart.js" /* webpackChunkName: "component---src-pages-endcart-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-despacho-js": () => import("./../../../src/pages/informacion-despacho.js" /* webpackChunkName: "component---src-pages-informacion-despacho-js" */),
  "component---src-pages-login-default-js": () => import("./../../../src/pages/login-default.js" /* webpackChunkName: "component---src-pages-login-default-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-my-directions-create-jsx": () => import("./../../../src/pages/my-directions-create.jsx" /* webpackChunkName: "component---src-pages-my-directions-create-jsx" */),
  "component---src-pages-my-directions-edit-jsx": () => import("./../../../src/pages/my-directions-edit.jsx" /* webpackChunkName: "component---src-pages-my-directions-edit-jsx" */),
  "component---src-pages-my-directions-js": () => import("./../../../src/pages/my-directions.js" /* webpackChunkName: "component---src-pages-my-directions-js" */),
  "component---src-pages-my-negrapoints-js": () => import("./../../../src/pages/my-negrapoints.js" /* webpackChunkName: "component---src-pages-my-negrapoints-js" */),
  "component---src-pages-my-payments-method-edit-jsx": () => import("./../../../src/pages/my-payments-method-edit.jsx" /* webpackChunkName: "component---src-pages-my-payments-method-edit-jsx" */),
  "component---src-pages-my-payments-methods-js": () => import("./../../../src/pages/my-payments-methods.js" /* webpackChunkName: "component---src-pages-my-payments-methods-js" */),
  "component---src-pages-my-shoppings-jsx": () => import("./../../../src/pages/my-shoppings.jsx" /* webpackChunkName: "component---src-pages-my-shoppings-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-personal-data-create-jsx": () => import("./../../../src/pages/personal-data-create.jsx" /* webpackChunkName: "component---src-pages-personal-data-create-jsx" */),
  "component---src-pages-personal-data-edit-jsx": () => import("./../../../src/pages/personal-data-edit.jsx" /* webpackChunkName: "component---src-pages-personal-data-edit-jsx" */),
  "component---src-pages-personal-data-js": () => import("./../../../src/pages/personal-data.js" /* webpackChunkName: "component---src-pages-personal-data-js" */),
  "component---src-pages-receta-js": () => import("./../../../src/pages/receta.js" /* webpackChunkName: "component---src-pages-receta-js" */),
  "component---src-pages-recetas-js": () => import("./../../../src/pages/recetas.js" /* webpackChunkName: "component---src-pages-recetas-js" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shipping-conditions-js": () => import("./../../../src/pages/shipping-conditions.js" /* webpackChunkName: "component---src-pages-shipping-conditions-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-write-review-js": () => import("./../../../src/pages/write-review.js" /* webpackChunkName: "component---src-pages-write-review-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/Tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

