// gatsby-browser.js
import React from 'react';
import { AppContext } from './src/context/AppContext';
import Layout from './src/components/Layout';
import './src/css/components/Styles.css';

export const wrapRootElement = ({ element }) => (
  <AppContext>
    <Layout>
      {element}
    </Layout>
  </AppContext>
);