import React, { useState } from "react";

const BrevoForm = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Activar el loading al iniciar la petición

    // Extraer el valor del input llamado "email"
    const emailValue = e.target.elements.email.value;

    // Construir el objeto JSON con el payload
    const data = {
      email: emailValue,
    };

    try {
      // Realizar la petición POST a la ruta de tu proxy en Laravel
      const response = await fetch(`${process.env.BASE_URL}/brevo-proxy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setMessage("¡Se ha suscrito de manera exitosa!");
      } else {
        setMessage("Hubo un error suscribiendo su correo!");
      }
    } catch (error) {
        setMessage("Hubo un error suscribiendo su correo!");
    } finally {
      setLoading(false); // Desactivar el loading al finalizar la petición
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="Footer__newsletter--input">
          <input type="text" name="email" id="email" placeholder="Mail *" required />
          <button type="submit" disabled={loading}>
            {loading ? (
              <div className="loading-container">
                <div className="spinner"></div>
                Suscribiendo...
              </div>
            ) : (
              "Suscribirme"
            )}
          </button>
        </div>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default BrevoForm;
