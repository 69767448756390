import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';
import { SearchResults } from './SearchResults';
import {useStaticQuery, graphql } from "gatsby"

const SearchComponent = ({ searchQuery = '' }) => { // Proporciona un valor por defecto para searchQuery
  const data = useStaticQuery(graphql`
  query {
    allProduct(
      sort: {date_created: DESC}
    ) {
      nodes {
        id
        wordpress_id
        internal_id
        sku
        name
        slug
        price
        ref_price
        sale_price
        description
        stock_status
        stock_quantity
        categories {
          id
          name
          slug
        }
        images {
          src
        }
        product_card_image
        attributes {
          id
          name
          options
          position
          slug
          visible
          variation
        }
        insignias {
          name
          bottom
          left
          margin_left
          margin_top
          mobile_scale
          opacity
          position
          position_type
          preview
          right
          rotation_x
          rotation_y
          rotation_z
          top
          width
          width_mobile
          media_id
        }
        total_sales
        date_created
        box
      }
    }
  }
  `);
  const [results, setResults] = useState([]);
  const [fuse, setFuse] = useState(null);
  // const [allProducts, setAllProducts] = useState();
  const allProducts = data.allProduct.nodes;
  // console.log(allProducts)
  // useEffect(() => {
  //   axios.get(`https://crmdust2.com/api/index/woo/products`)
  //     .then(response => {
  //       setAllProducts(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error al obtener los productos:', error);
  //     });
  // }, []);

  useEffect(() => {
    if (allProducts && allProducts.length > 0) {
      const fuseOptions = {
        keys: ['name', 'sku', 'categories.name'], // Campos donde buscar
        threshold: 0.3, // Nivel de flexibilidad (0: exacto, 1: más flexible)
        includeScore: true, // Incluir puntuación en los resultados
      };
      setFuse(new Fuse(allProducts, fuseOptions));
    }
  }, [allProducts]);

  // Ejecutar búsqueda cuando cambia searchQuery
  useEffect(() => {
    if (fuse && searchQuery.trim()) {
      const normalizedQuery = searchQuery.trim().toLowerCase();
      const searchResults = fuse
        .search(normalizedQuery)
        .map((result) => result.item); // Mapeamos solo a los elementos encontrados
      setResults(searchResults);
    } else {
      setResults([]); // Reseteamos resultados si no hay búsqueda
    }
  }, [searchQuery, fuse]);


  return (
    <div>
      {results.length > 0 ? (
          <SearchResults results={results} />
      ) : searchQuery ? (
          <p>No se encontraron resultados para "{searchQuery}".</p>
      ) : (
          <p>Escribe algo para buscar productos.</p>
      )}
    </div>
  );
};

export default SearchComponent;
